import axios from "axios";

interface RVOProps {
  firstName: string;
  lastName: string;
  email: string;
  debt: number;
  income: number;
  affordability: number;
  increasedAffordability?: number;
  mcUsername?: string;
  mcApiKey?: string;
  media?: boolean;
  phone: string;
  term: number;
  percentageDown: number;
}

export const createRVO = (props: RVOProps) =>
  axios.post(
    `https://loan-sense-microservice.now.sh/api/mortgage-coach`,
    props
  );
