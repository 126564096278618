//@ts-nocheck
import axios from "axios";

export const initialize = () => {
  if (window?.sendinblue) {
    console.warn("Sendinblue already initialized");
  } else if (typeof window !== "undefined") {
    (function() {
      window.sib = {
        equeue: [],
        client_key: "7rwlyarc7zaaj020w8c4ngkb",
      };
      /* OPTIONAL: email for identify request*/
      // window.sib.email_id = 'example@domain.com';
      window.sendinblue = {};
      for (
        var j = ["track", "identify", "trackLink", "page"], i = 0;
        i < j.length;
        i++
      ) {
        (function(k) {
          window.sendinblue[k] = function() {
            var arg = Array.prototype.slice.call(arguments);
            (
              window.sib[k] ||
              function() {
                var t: any = {};
                t[k] = arg;
                window.sib.equeue.push(t);
              }
            )(arg[0], arg[1], arg[2]);
          };
        })(j[i]);
      }

      var n = document.createElement("script"),
        /* eslint-disable */
        i = document.getElementsByTagName("script")[0];
      (n.type = "text/javascript"),
        (n.id = "sendinblue-js"),
        (n.async = !0),
        (n.src =
          "https://sibautomation.com/sa.js?key=" + window.sib.client_key),
        i.parentNode.insertBefore(n, i),
        window.sendinblue.page();
      sendinblue = window.sendinblue;
      /* eslint-enable */
    })();
  }
};

export const identify = (...args) => {
  if (!window?.sendinblue) {
    console.warn(
      "You must initialize sendinblue before you call the identify method"
    );
    return;
  }

  window.sendinblue.identify(...args);
};

export const track = async (event, { email, ...properties }) => {
  try {
    await axios.post(`https://loan-sense-microservice.now.sh/api/sib`, {
      email,
      properties,
      event,
    });
  } catch (e) {
    // no catch
  }
};
