import { Box, Text } from "@chakra-ui/react";
import { ButtonLink, Link, Button } from "../../platform";
import styled from "@emotion/styled";
import { WidgetProps } from "./types";

export const Container = styled(Box)`
  max-width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 20px;
`;

export const WidgetContainer = styled(Box)<WidgetProps>`
  background-color: ${(props) => props.background};
  padding-top: 50px;
  padding-bottom: 50px;
  filter: ${(props) => (props.blur ? "blur(6px);" : "")};
`;

export const TitleWrapper = styled(Box)`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1180px) {
    width: 100%;
    margin: 0 auto;
    padding-left: 10px;
  }
  @media (max-width: 500px) {
    text-align: -webkit-center;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  .bigPrice {
    font-weight: 900;
    font-size: 35px;
  }
  .lowerMonth {
    font-size: 16px;
    margin-left: 5px;
  }
  .blackTitle {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    width: 100%;
  }
  .grayTitle {
    color: #7d83a0;
    font-weight: 700;
    font-size: 14px;
  }
  .grayPrice {
    color: #5b6179;
    font-weight: 700;
    font-size: 22px;
  }
  .colorTitle {
    filter: opacity(60%);
    font-weight: 700;
    font-size: 14px;
  }
  .colorPrice {
    font-weight: 700;
    font-size: 22px;
  }
  .bigTitle {
    font-weight: 900;
    font-size: 24px;
  }
  .poweredBy {
    text-align: -webkit-center;
  }
  @media (max-width: 1175px) {
    .rightSection {
      display: block;
    }
    .lastStep {
      margin-top: 20px;
      width: 100%;
      text-align: -webkit-center;
      height: auto;
    }
    .poweredBy {
      margin-top: 50px;
    }
    .calculation {
      width: 100%;
    }
  }
  @media (max-width: 870px) {
    &.main {
      display: block;
    }
    .lastStep {
      margin-left: 0;
      margin-top: 0;
      height: fit-content;
    }

    .calculation {
      height: auto;
      margin-bottom: 20px;
    }
    .rightSection {
      display: flex;
    }
    .leftSection {
      display: flex;
    }
    .firstStep {
      margin-right: 20px;
      width: 100%;
    }
    .bigPrice {
      font-size: 28px;
    }
    .blackTitle {
      font-size: 12px;
    }
    .secondStep {
      width: 100%;
      height: fit-content;
    }
    .blackTitle {
      width: 1%;
    }
  }
  @media (max-width: 825px) {
    .rightSection {
      display: block;
    }
    .blackTitle {
      width: 100%;
    }
  }
  @media (max-width: 725px) {
    .leftSection {
      display: block;
    }
  }
  @media (max-width: 520px) {
    display: block;

    .colorLine {
      margin: 15px 30px 5px;
      height: 2px;
      width: 80%;
    }
    .mortgageBox {
      display: block;
    }
    .blackTitle {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .rightSectionTitle {
      display: initial;
    }
    .shareTitle {
      position: initial;
    }
  }
`;

export const Section = styled(Box)`
  padding: 10px;
`;

export const LoadingWrapper = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InputTitle = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  color: #484f6f;
  line-height: 40px;
  display: flex;
`;

export const StepTitle = styled(Text)`
  font-weight: 900;
  font-size: 21px;
  margin-bottom: 15px;
`;

// export const MortgageBox = styled(Box)`
//   margin-top: 80px;
//   justify-content: center;
//   margin-bottom: 40px;
// `;

export const HorizontalLine = styled(Box)`
  width: 150px;
  margin: 15px 0 5px;
  filter: opacity(60%);
`;
export const Badge = styled(ButtonLink)`
  margin-top: 55px;
  border-radius: 14px;
  width: 250px;
  height: 82px;
  display: flex;
`;

export const Banner = styled(Link)`
  background: #f6f7ff;
  max-width: 1160px;
  margin: 0 auto;
  display: block;
  width: 100%;
`;

export const ShareButton = styled(Button)`
  width: 300px;
  height: 37px;
  left: 180px;
  bottom: 5px;
  background-color: ;
  border: 1px solid;
  border-radius: 9px;
  @media (max-width: 879px) {
    left: 140px;
  }
  @media (max-width: 825px) {
    left: 175px;
  }
  @media (max-width: 500px) {
    left: 0;
  }
`;
