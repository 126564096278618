import axios from "axios";

interface InviteProps {
  email: string;
  lenderEmail: string;
  isSubsidized: boolean;
  phone: string;
  firstName: string;
  lastName: string;
}

export const createLenderInvite = (props: InviteProps) =>
  axios.post(
    `https://loan-sense-microservice.now.sh/api/lender-invite/create`,
    props,
    {
      headers: { "x-api-key": "PHVJF4CZ83413JQDSTM76ESECJAP" },
    }
  );
