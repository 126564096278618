import React, { useEffect, useState } from "react";
import parsePhoneNumber, {
  isValidPhoneNumber,
  AsYouType,
} from "libphonenumber-js";
import {
  Input,
  Box,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Flex,
  useToast,
  CircularProgress,
  Link as ChakraLink,
  RadioGroup,
  Radio,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Switch,
  Icon,
  Collapse,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
} from "@chakra-ui/react";
import feather from "feather-icons";
import { BsHouseFill } from "react-icons/bs";
import {
  FaDollarSign,
  FaPercentage,
  FaMinus,
  FaPlus,
  FaCheckCircle,
} from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import {
  calculatePrincipal,
  calculateMonthly,
  calculateSAVEPayment,
} from "../../utils/loans";
import { Hint, Button } from "../../platform";
import {
  Container,
  WidgetContainer,
  Wrapper,
  TitleWrapper,
  Section,
  InputTitle,
  StepTitle,
  HorizontalLine,
  Badge,
  Banner,
  LoadingWrapper,
  ShareButton,
} from "./styles";
import isDefined, { orZero } from "../../utils/isDefined";
// @ts-ignore
import Logo from "../../images/loansense-logo.png";
import EhlLogo from "../../images/ehl_logo.png";
import privacyIcon from "../../images/privacy-icon.png";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { createRVO } from "../../services/mortgage-coach";
import { createLenderInvite } from "../../services/lender-invites";
import { track } from "../../utils/sendin-blue";
import { buildWidgetCode } from "../../utils/widget-code";

const whiteListedUrls = [
  "https://www.myloansense.com/purchasing-power-tool",
  "https://loansense-mono-repo.netlify.app/",
  "http://localhost:8000/purchasing-power-tool",
  "http://localhost:1234/",
  "https://tools.myloansense.com/",
];

const digitRegex = new RegExp(/^-?\d*\.?\d*$/);

const Widget = ({
  removeHeader,
  apiKey,
  primaryColor = "indigo",
  background,
  showBanner = false,
  lenderVersion,
  mcApiKey,
  mcUsername,
  media,
  collectEmail,
  partnerCode,
  displayName,
  website,
  prefilledData,
  nonMc,
  nmls,
  lenderPhone,
  lenderEmail,
  organization,
  organizationWide,
  showEhl,
}) => {
  const toast = useToast();
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [drop, setDrop] = useState(false);
  const [annualIncome, setAnnualIncome] = useState(
    prefilledData?.defaultAnnualIncome
  );
  const [maximumMonthlyPayment, setMaximumMonthlyPayment] = useState();
  const [closeDate, setCloseDate] = useState();
  const [federalStudentLoanBalance, setFederalStudentLoanBalance] = useState(
    prefilledData?.defaultStudentLoanBalance > -1
      ? prefilledData?.defaultStudentLoanBalance
      : undefined
  );

  const [federalStudentLoanPayment, setFederalStudentLoanPayment] = useState(
    prefilledData?.defaultStudentLoanPayment > -1
      ? prefilledData?.defaultStudentLoanPayment
      : undefined
  );

  const [minCreditCardPayment, setMinCreditCardPayment] = useState(
    prefilledData?.defaultMinCreditCardPayment > -1
      ? prefilledData?.defaultMinCreditCardPayment
      : undefined
  );
  const [carPayment, setCarPayment] = useState(
    prefilledData?.defaultCarPayment > -1
      ? prefilledData?.defaultCarPayment
      : undefined
  );
  const [otherLoans, setOtherLoans] = useState(
    prefilledData?.defaultOtherLoans > -1
      ? prefilledData?.defaultOtherLoans
      : undefined
  );
  const [studentLoanInterest, setStudentLoanInterest] = useState<number>(
    !lenderVersion ? 0.06 : prefilledData?.defaultAverageStudentLoanRate
  );
  const [studentLoanInterestString, setStudentLoanInterestString] = useState(
    !lenderVersion
      ? "0.06"
      : prefilledData?.defaultAverageStudentLoanRate &&
          `${prefilledData?.defaultAverageStudentLoanRate}`
  );
  const [familySize, setFamilySize] = useState(
    !lenderVersion ? 1 : prefilledData?.defaultFamilySize
  );
  const [debtToIncome, setDebtToIncome] = useState(
    prefilledData?.defaultDTI || 0.45
  );
  const [debtToIncomeString, setDebtToIncomeString] = useState(
    `${prefilledData?.defaultDTI || 0.45}`
  );
  const [loanTerm, setLoanTerm] = useState(
    prefilledData?.defaultLoanTerm || 30
  );
  const [interestRate, setInterestRate] = useState(
    prefilledData?.defaultMortgageInterestRate || 0.045
  );
  const [interestRateString, setInterestRateString] = useState(
    `${prefilledData?.defaultMortgageInterestRate || 0.045}`
  );
  const [percentageDown, setPercentageDown] = useState(
    prefilledData?.defaultDownPaymentPercentage > -1
      ? prefilledData?.defaultDownPaymentPercentage
      : 0.03
  );
  const [percentageDownString, setPercentageDownString] = useState(
    `${prefilledData?.defaultDownPaymentPercentage || 0.03}`
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isPrivacyOpen,
    onOpen: onPrivacyOpen,
    onClose: onPrivacyClose,
  } = useDisclosure();
  const [copySuccess, setCopySuccess] = useState(false);
  const [errorText, setErrorText] = useState(
    "From the looks of it - you can't afford more than a $100,000 home."
  );
  const [canShowError, setCanShowError] = useState(true);
  const [firstName, setFirstName] = useState(prefilledData?.firstName || "");
  const [lastName, setLastName] = useState(prefilledData?.lastName || "");
  const [email, setEmail] = useState(prefilledData?.clientEmail || "");
  const [phone, setPhone] = useState(prefilledData?.phone || "");
  const [formError, setFormError] = useState("");
  const [collectEmailInvalid, setCollectEmailInvalid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [manualLoading, setManualLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDashes, setShowDashes] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [subsidize, setSubsidize] = useState("");
  const [modalType, setModalType] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [includeResults, setIncludeResults] = useState(true);
  const [showLeadCapture, setShowLeadCapture] = useState(true);
  const [reasonForInvite, setReasonForInvite] = useState("");
  const [mortgageOption, setMortgageOption] = useState("");

  const validateEmail = () => {
    if (!email) {
      setEmailError("Please Enter Your Email");
      setCollectEmailInvalid(true);
      throw new Error("email error");
    }

    if (!/^[^\s@]+@[^\s@]+$/.test(email)) {
      setEmailError("Please Enter a Valid Email.");
      setCollectEmailInvalid(true);
      throw new Error("email error");
    }
  };

  const sendEmail = async () => {
    try {
      validateEmail();
    } catch (e) {
      return;
    }

    console.log(
      maximumMonthlyPayment,
      closeDate,
      mortgageOption,
      reasonForInvite
    );

    if (!firstName) {
      setFormError('"First Name" is required.');
      return;
    }
    let website = `https://app.myloansense.com?partner=${partnerCode}-Subsidized&affordability=${increasedPurchasingPower}`;
    if (subsidize === "no") {
      website = `https://app.myloansense.com?partner=${partnerCode}&affordability=${increasedPurchasingPower}`;
    }
    const lenderName = organizationWide
      ? ""
      : partnerCode
          .toLowerCase()
          .replace(/-/g, " ")
          .replace(/\b\w/g, (m: string) => m.toUpperCase());

    const properties = {
      FIRSTNAME: firstName,
      LASTNAME: lastName,
      email,
      ORGANIZATION: organization,
      WEBSITE: website,
      LENDER_EMAIL: lenderEmail,
      LENDER_NAME: lenderName,
      PHONE_NUMBER: phone,
      SMS: parsePhoneNumber(phone, "US")
        ?.formatInternational()
        ?.replace(/\s+/g, ""),
      MORTGAGE_OPTION: mortgageOption,
      INREASON: reasonForInvite,
      CLOSE_DATE: closeDate,
      MAX_MONTHLY_PAYMENT: maximumMonthlyPayment,
    };
    if (nonMc) {
      track("lslender_invite", {
        ...properties,
        AFFORDABILITY: includeResults ? increasedPurchasingPower : undefined,
        SAVINGS: includeResults ? monthlySavings : undefined,
      });
    } else {
      track("lender_invite", {
        ...properties,
        RVO_LINK: data?.data?.rvo?._text,
        AFFORDABILITY: includeResults ? increasedPurchasingPower : undefined,
        SAVINGS: includeResults ? monthlySavings : undefined,
      });
    }
    try {
      await createLenderInvite({
        email,
        lenderEmail,
        isSubsidized: subsidize !== "no",
        firstName,
        lastName,
        phone,
      });
    } catch (e) {}
    onClose();
    onConfirmOpen();
    getRVO();
  };

  const sendConsumerEmail = async () => {
    try {
      validateEmail();
    } catch (e) {
      return;
    }
    const lenderName = partnerCode
      .toLowerCase()
      .replace(/-/g, " ")
      .replace(/\b\w/g, (m: string) => m.toUpperCase());

    track("lo_consumer_lead", {
      FIRSTNAME: clientFirstName,
      LASTNAME: clientLastName,
      LENDER_EMAIL: lenderEmail,
      LENDER_NAME: lenderName,
      email,
      PHONE_NUMBER: phone,

      ORGANIZATION: organization,
      AFFORDABILITY:
        increasedPurchasingPower !== "$0"
          ? increasedPurchasingPower
          : undefined,
      SAVINGS: monthlySavings !== "$0" ? monthlySavings : undefined,
      INCOME:
        annualIncome &&
        annualIncome.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }),
      LOAN_BALANCE:
        federalStudentLoanBalance &&
        federalStudentLoanBalance.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }),
      CC_PAYMENT: isDefined(minCreditCardPayment)
        ? minCreditCardPayment.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          })
        : undefined,
      CAR_PAYMENT: isDefined(carPayment)
        ? carPayment.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          })
        : undefined,
      OTHER_LOANS:
        otherLoans &&
        otherLoans.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }),
      AFTER_MORG_AMT:
        Math.round(totalHomeLoan(studentLoanPaymentAfter())) &&
        Math.round(totalHomeLoan(studentLoanPaymentAfter())).toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "USD",
          }
        ),
      BEFORE_MORG_AMT:
        Math.round(totalHomeLoan(studentLoanPaymentBefore())) &&
        Math.round(totalHomeLoan(studentLoanPaymentBefore())).toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 0,
            style: "currency",
            currency: "USD",
          }
        ),
      AFTER_MORT_PYT:
        Math.round(monthlyMortgagePayment(studentLoanPaymentAfter())) &&
        Math.round(
          monthlyMortgagePayment(studentLoanPaymentAfter())
        ).toLocaleString("en-US", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "USD",
        }),
      BEFORE_MORT_PYT:
        Math.round(monthlyMortgagePayment(studentLoanPaymentBefore())) &&
        Math.round(
          monthlyMortgagePayment(studentLoanPaymentBefore())
        ).toLocaleString("en-US", {
          maximumFractionDigits: 0,
          style: "currency",
          currency: "USD",
        }),
    });
    try {
      await createLenderInvite({
        email,
        lenderEmail,
        isSubsidized: false,
        firstName: clientFirstName,
        lastName: clientLastName,
        phone,
      });
    } catch (e) {}
    onConfirmOpen();
  };

  const { isLoading, error } = useQuery(
    "apiKey",
    async () => {
      return await axios.get(
        `https://loan-sense-microservice.now.sh/api/widget-auth`,
        {
          params: { API_KEY: apiKey },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !whiteListedUrls.includes(window.location.href.split("?")[0]),
    }
  );

  const studentLoanPaymentBefore = (): number => {
    if (federalStudentLoanPayment && federalStudentLoanPayment > 0)
      return federalStudentLoanPayment;
    const studentLoanPayment = calculateMonthly(
      orZero(federalStudentLoanBalance),
      orZero(studentLoanInterest),
      120
    );
    if (!studentLoanPayment || studentLoanPayment < 0) {
      return 0;
    } else {
      return studentLoanPayment;
    }
  };

  const studentLoanPaymentAfter = (): number => {
    const extendedPlan =
      federalStudentLoanBalance >= 30000
        ? calculateMonthly(
            orZero(federalStudentLoanBalance),
            orZero(interestRate),
            25 * 12
          )
        : studentLoanPaymentBefore();

    const savePayment = calculateSAVEPayment(
      orZero(familySize),
      orZero(annualIncome)
    );
    if (!extendedPlan || !savePayment) {
      return 0;
    }
    return Math.min(extendedPlan, savePayment, studentLoanPaymentBefore());
  };

  const monthlyMortgagePayment = (studentLoanPayment: number) => {
    const possibleDebtAllowable =
      (orZero(annualIncome) / 12) * orZero(debtToIncome);
    const affordHousePayment =
      possibleDebtAllowable -
      (orZero(minCreditCardPayment) +
        orZero(otherLoans) +
        orZero(carPayment) +
        orZero(studentLoanPayment));
    if (affordHousePayment < 0 || !affordHousePayment) {
      return 0;
    } else {
      return affordHousePayment;
    }
  };

  const totalHomeLoan = (studentLoanPayment: number): number => {
    const affordHome = calculatePrincipal(
      monthlyMortgagePayment(studentLoanPayment) * 0.7,
      orZero(interestRate),
      loanTerm * 12
    );
    if (!affordHome || affordHome < 0) {
      return 0;
    }
    return affordHome + orZero(percentageDown) * affordHome;
  };

  const increasedPurchasingPower =
    Math.round(totalHomeLoan(studentLoanPaymentAfter())) -
      Math.round(totalHomeLoan(studentLoanPaymentBefore())) <
    0
      ? "$0"
      : (
          Math.round(totalHomeLoan(studentLoanPaymentAfter())) -
          Math.round(totalHomeLoan(studentLoanPaymentBefore()))
        ).toLocaleString("en-US", {
          maximumFractionDigits: 0,
          currency: "USD",
          style: "currency",
        });

  const monthlySavings =
    Math.round(studentLoanPaymentBefore()) -
      Math.round(studentLoanPaymentAfter()) <=
    0
      ? "0"
      : (
          Math.round(studentLoanPaymentBefore()) -
          Math.round(studentLoanPaymentAfter())
        ).toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
  const [mutate, { isLoading: rvoIsLoading, data }] = useMutation(createRVO);

  const getRVO = async () => {
    if (lenderVersion && !nonMc) {
      mutate({
        income: annualIncome,
        term: loanTerm * 12,
        percentageDown,
        firstName,
        lastName,
        email,
        phone,
        affordability: totalHomeLoan(studentLoanPaymentBefore()),
        increasedAffordability: totalHomeLoan(studentLoanPaymentAfter()),
        mcUsername,
        mcApiKey,
        media,
        debt:
          minCreditCardPayment +
          otherLoans +
          carPayment +
          studentLoanPaymentAfter(),
      });
    }
  };

  const closeModal = () => {
    onClose();
    setCanShowError(false);
  };

  const copyToClipboard = async (str) => {
    try {
      await window.navigator.clipboard.writeText(str);
      toast({
        description: "Item copied to clipboard",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {}
    setCopySuccess(true);
  };

  useEffect(() => {
    if (
      !(
        isDefined(annualIncome) &&
        isDefined(federalStudentLoanBalance) &&
        isDefined(studentLoanInterest) &&
        isDefined(familySize)
      )
    ) {
      setShowDashes(true);
    } else {
      setShowDashes(false);
    }
  }, [
    annualIncome,
    federalStudentLoanBalance,
    studentLoanInterest,
    familySize,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const totalMortgageLoan = totalHomeLoan(studentLoanPaymentAfter());
    if (
      canShowError &&
      isDefined(annualIncome) &&
      isDefined(federalStudentLoanBalance) &&
      isDefined(studentLoanInterest) &&
      isDefined(familySize)
    ) {
      if (totalMortgageLoan < 100000) {
        setErrorText(
          "From the looks of it - you can't afford more than a $100,000 home."
        );
        setShowError(true);
      } else if (
        Math.round(studentLoanPaymentBefore()) ===
        Math.round(studentLoanPaymentAfter())
      ) {
        console.log(studentLoanPaymentBefore(), studentLoanPaymentAfter());
        setErrorText(
          "From the looks of it - our estimates can't save you money."
        );
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false);
    }
  });

  useEffect(() => {
    if (increasedPurchasingPower) {
      track("affordability_updated", { email: email || lenderEmail });
    }
  }, [increasedPurchasingPower]);

  const who = lenderVersion ? "YOUR CLIENT" : "YOU";

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  return isLoading || rvoIsLoading || manualLoading ? (
    <LoadingWrapper>
      <CircularProgress
        size="125px"
        isIndeterminate
        style={{ color: primaryColor }}
      ></CircularProgress>
    </LoadingWrapper>
  ) : (
    <WidgetContainer blur={!error ? false : true} background={background}>
      <Flex
        width="1230px !important"
        maxWidth="100%"
        margin="0 auto"
        alignItems={{ base: "flex-start", lg: "center" }}
        flexDir={{ base: "column", lg: "row" }}
      >
        {!removeHeader && (
          <TitleWrapper>
            <Text fontWeight={900} fontSize="34px" color="#3E435B">
              {lenderVersion
                ? "Lender tool to Increase Your Clients Home buying Power"
                : "Increase your Purchasing Power by Lowering Your Student Loan Payments"}
            </Text>
            <Text fontWeight="500" color="#3E435B" fontSize="18px" mb={4}>
              {lenderVersion
                ? "This tool will calculate an estimate of how much your client can reduce their student loan payment and how much more house it will potentially allow them to purchase. "
                : "Use this calculator to estimate how much more you can qualify for on your next home loan by reducing your student loan payments.  Need results fast?  We specialize in helping borrowers that are looking for a mortgage now.  Typical processing times are 30 days or less.  Let’s get started!"}
            </Text>
          </TitleWrapper>
        )}
        {!lenderVersion && nmls && (
          <Stack
            background="#F6F7FF"
            w="625px"
            maxW="100%"
            borderRadius="8px"
            spacing={1}
            p={3}
            position="relative"
          >
            <Text color={primaryColor} fontWeight={500} fontSize="18px">
              {displayName
                ? displayName
                : `Loan Officer: ${partnerCode.replace(/-/g, " ")}`}
            </Text>
            <Text color={primaryColor} fontWeight={500} fontSize="18px">
              NMLS #{nmls}
            </Text>
            {website && (
              <Text color={primaryColor} fontWeight={500} fontSize="18px">
                {website}
              </Text>
            )}
            <Flex justify="space-between">
              <Box>
                <Text color={primaryColor} fontWeight={500} fontSize="18px">
                  {lenderEmail}
                </Text>
                {lenderPhone && (
                  <Text
                    mt={1}
                    color={primaryColor}
                    fontWeight={500}
                    fontSize="18px"
                  >
                    {lenderPhone}
                  </Text>
                )}
              </Box>
              {showEhl && (
                <Image
                  alignSelf="flex-end"
                  src={EhlLogo}
                  alt="EHL Logo"
                  h={10}
                />
              )}
            </Flex>
          </Stack>
        )}
      </Flex>
      {showBanner && (
        <Banner
          color="#7d83a0"
          p={4}
          fontWeight={600}
          _hover={{ textDecoration: "none" }}
          href="https://calendly.com/loansense/purchasing-power-tool"
          target="_blank"
          rel="noreferrer noopener"
        >
          Mortgage Originators and Real Estate Brokers - Close more purchases at
          higher values for student loan holders. Get this purchasing power tool
          on your website.{" "}
          <Text color="indigo" as="span">
            Contact sales.
          </Text>
        </Banner>
      )}
      <Wrapper className="main">
        <Section className="leftSection" paddingLeft="0">
          <Container
            className="firstStep"
            width="350px"
            marginBottom="20px"
            backgroundColor={
              primaryColor === "indigo" ? "#F6F7FF !important" : "white"
            }
          >
            <StepTitle color={primaryColor}>STEP 01: BASIC INFO</StepTitle>
            <InputTitle>
              Annual Income (Before Taxes)
              <Hint
                margin="12px 10px"
                iconName="question"
                direction="right"
                message={`<strong class="boldText">Include your spouses' income</strong>, if you plan to purchase the house with them. If you include your spouses' income, then also <strong class="boldText">include your spouse's student loan debt in the next line.</strong>`}
                color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
              />
            </InputTitle>
            <InputGroup>
              <InputLeftElement children={<Box as={FaDollarSign} />} />
              <Input
                type="text"
                marginTop="1px"
                placeholder="50,000"
                background="white"
                value={
                  isDefined(annualIncome)
                    ? annualIncome.toLocaleString("en-US")
                    : ""
                }
                onChange={(event: any) => {
                  if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setCanShowError(false);
                  setAnnualIncome(
                    isDefined(event.target.value)
                      ? parseInt(event.target.value.replace(/,/g, ""), 10)
                      : 0
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(annualIncome) && setAnnualIncome(0);
                }}
              ></Input>
            </InputGroup>
            <InputTitle>
              Federal Student Loans Balance
              <Hint
                margin="12px 10px"
                iconName="question"
                direction="right"
                message={`<strong class="boldText">Include your spouse's student loan balance as well. Include private student loans in "other loans"</strong>. Federal loans are public loans managed by FedLoans, Great Lakes, Nelnet, Mohela, Granite State, Corner Stone, ECSI, OSLA servicing. Navient loan may be public or private loans.`}
                color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
              />
            </InputTitle>
            <InputGroup>
              <InputLeftElement children={<Box as={FaDollarSign} />} />
              <Input
                background="white"
                marginTop="1px"
                placeholder="50,000"
                value={
                  isDefined(federalStudentLoanBalance)
                    ? federalStudentLoanBalance.toLocaleString("en-US")
                    : ""
                }
                onChange={(event: any) => {
                  if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setCanShowError(false);
                  setFederalStudentLoanBalance(
                    isDefined(event.target.value)
                      ? parseInt(event.target.value.replace(/,/g, ""), 10)
                      : undefined
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(federalStudentLoanBalance) &&
                    setFederalStudentLoanBalance(0);
                }}
              ></Input>
            </InputGroup>
            {lenderVersion && (
              <>
                <InputTitle>
                  Federal Student Loan Payment
                  <Hint
                    margin="12px 10px"
                    iconName="question"
                    direction="right"
                    message={`<strong class="boldText">Include your client's monthly student loan payment if you have it. This will take precendence over student loan balance.</strong> Student loan balance is still required to get the most accurate recommendation.`}
                    color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
                  />
                </InputTitle>
                <InputGroup>
                  <InputLeftElement children={<Box as={FaDollarSign} />} />
                  <Input
                    background="white"
                    marginTop="1px"
                    placeholder="700"
                    value={
                      isDefined(federalStudentLoanPayment)
                        ? federalStudentLoanPayment.toLocaleString("en-US")
                        : ""
                    }
                    onChange={(event: any) => {
                      if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                        return;
                      }
                      setCanShowError(false);
                      setFederalStudentLoanPayment(
                        isDefined(event.target.value)
                          ? parseInt(event.target.value.replace(/,/g, ""), 10)
                          : undefined
                      );
                    }}
                    onBlur={() => {
                      setCanShowError(true);
                      !isDefined(federalStudentLoanPayment) &&
                        setFederalStudentLoanPayment(0);
                    }}
                  ></Input>
                </InputGroup>
              </>
            )}
            <InputTitle>Minimum Credit Card Payment</InputTitle>
            <InputGroup>
              <InputLeftElement children={<Box as={FaDollarSign} />} />
              <Input
                background="white"
                marginTop="1px"
                value={
                  isDefined(minCreditCardPayment)
                    ? minCreditCardPayment.toLocaleString("en-US")
                    : ""
                }
                placeholder="500"
                onChange={(event: any) => {
                  if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setMinCreditCardPayment(
                    isDefined(event.target.value)
                      ? parseInt(event.target.value.replace(/,/g, ""), 10)
                      : undefined
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(minCreditCardPayment) &&
                    setMinCreditCardPayment(0);
                }}
              ></Input>
              <InputRightElement
                fontWeight="500"
                marginRight="10px"
                children="/Month"
              />
            </InputGroup>
            <InputTitle>Car Payment</InputTitle>
            <InputGroup>
              <InputLeftElement children={<Box as={FaDollarSign} />} />
              <Input
                background="white"
                marginTop="1px"
                placeholder="800"
                value={
                  isDefined(carPayment)
                    ? carPayment.toLocaleString("en-US")
                    : ""
                }
                onChange={(event: any) => {
                  if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setCarPayment(
                    isDefined(event.target.value)
                      ? parseInt(event.target.value.replace(/,/g, ""), 10)
                      : undefined
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(carPayment) && setCarPayment(0);
                }}
              ></Input>
              <InputRightElement
                fontWeight="500"
                marginRight="10px"
                children="/Month"
              />
            </InputGroup>
            <InputTitle>
              Other Loans
              <Hint
                margin="12px 10px"
                iconName="question"
                direction="right"
                message={`<strong class="boldText">Include any payments reported on your credit report.</strong> Example items: private student loans, other mortgage loans (not this mortgage loan you are trying to qualify for), personal loans, child support, alimony, etc. Items NOT on your credit report, such as owing your brother money, should NOT be included.`}
                color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
              />
            </InputTitle>
            <InputGroup>
              <InputLeftElement children={<Box as={FaDollarSign} />} />
              <Input
                background="white"
                marginTop="1px"
                placeholder="1,000"
                value={
                  isDefined(otherLoans)
                    ? otherLoans.toLocaleString("en-US")
                    : ""
                }
                onChange={(event: any) => {
                  if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setOtherLoans(
                    isDefined(event.target.value)
                      ? parseInt(event.target.value.replace(/,/g, ""), 10)
                      : undefined
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(otherLoans) && setOtherLoans(0);
                }}
              ></Input>
              <InputRightElement
                fontWeight="500"
                marginRight="10px"
                children="/Month"
              />
            </InputGroup>
          </Container>
          <Container
            className="secondStep"
            width="350px"
            backgroundColor={
              primaryColor === "indigo" ? "#F6F7FF !important" : "white"
            }
          >
            <StepTitle color={primaryColor}>STEP 02:</StepTitle>
            <InputTitle>
              Average Student Loan Interest Rate
              <Hint
                margin="12px 10px"
                iconName="question"
                direction="right"
                message={`This is only an estimate and if you don't know list 6% for graduate loans, 5% for undergrad loans, 8% for parent plus loans.`}
                color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
              />
            </InputTitle>
            <InputGroup>
              <Input
                background="white"
                value={
                  studentLoanInterestString?.endsWith(".")
                    ? studentLoanInterestString
                    : isDefined(studentLoanInterest)
                    ? (studentLoanInterest * 100).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                marginTop="1px"
                placeholder="6"
                onChange={(event) => {
                  if (!digitRegex.test(event.target.value.replace(/,/g, ""))) {
                    return;
                  }
                  setStudentLoanInterestString(event.target.value);
                  setStudentLoanInterest(
                    isDefined(event.target.value)
                      ? parseFloat(event.target.value.replace(/,/g, "")) / 100
                      : undefined
                  );
                }}
                onBlur={() => {
                  setCanShowError(true);
                  !isDefined(studentLoanInterest) &&
                    setStudentLoanInterest(0.06);
                }}
              />
              <InputRightElement
                fontWeight="500"
                marginRight="10px"
                children={<Box as={FaPercentage} />}
              />
            </InputGroup>
            <InputTitle>Family Size</InputTitle>
            <Input
              background="white"
              value={isDefined(familySize) ? familySize : ""}
              marginTop="1px"
              placeholder="1"
              onChange={(event) => {
                if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                  return;
                }
                setFamilySize(
                  isDefined(event.target.value)
                    ? parseInt(event.target.value, 10)
                    : undefined
                );
              }}
              onBlur={() => {
                setCanShowError(true);
                !isDefined(familySize) && setFamilySize(1);
              }}
            ></Input>
            {expanded && (
              <Box>
                <InputTitle>
                  Debt-To-Income Ratio
                  <Hint
                    margin="12px 10px"
                    iconName="question"
                    direction="right"
                    message={`Your debt-to-income ratio is all your monthly debt payments divided by your pre tax monthly income. It is what lenders use to evaluate <strong class="boldText">how much you can afford to pay back.</strong> Ask your lender about your DTI requirement.`}
                    color={primaryColor === "indigo" ? "#2e49ec" : primaryColor}
                  />
                </InputTitle>
                <InputGroup>
                  <Input
                    background="white"
                    marginTop="1px"
                    placeholder="45"
                    value={
                      debtToIncomeString?.endsWith(".")
                        ? debtToIncomeString
                        : isDefined(debtToIncome)
                        ? (debtToIncome * 100).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                        : ""
                    }
                    onChange={(event: any) => {
                      if (
                        !digitRegex.test(event.target.value.replace(/,/g, ""))
                      ) {
                        return;
                      }
                      setDebtToIncomeString(event.target.value);
                      setDebtToIncome(
                        isDefined(event.target.value)
                          ? parseFloat(event.target.value.replace(/,/g, "")) /
                              100
                          : undefined
                      );
                    }}
                    onBlur={() => {
                      setCanShowError(true);
                      !isDefined(debtToIncome) && setDebtToIncome(0.45);
                    }}
                  />
                  <InputRightElement
                    fontWeight="500"
                    marginRight="10px"
                    children={<Box as={FaPercentage} />}
                  />
                </InputGroup>
                <InputTitle>Loan Term</InputTitle>
                <Select
                  background="white"
                  value={loanTerm}
                  iconColor={primaryColor}
                  onChange={(event: any) => setLoanTerm(event.target.value)}
                  onBlur={() => setCanShowError(true)}
                >
                  <option value={10}>10 Year Fixed</option>
                  <option value={15}>15 Year Fixed</option>
                  <option value={20}>20 Year Fixed</option>
                  <option value={30}>30 Year Fixed</option>
                </Select>
                <InputTitle>Mortgage Interest Rate</InputTitle>
                <InputGroup>
                  <Input
                    background="white"
                    marginTop="1px"
                    placeholder="5"
                    value={
                      interestRateString?.endsWith(".")
                        ? interestRateString
                        : isDefined(interestRate)
                        ? (interestRate * 100).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                        : ""
                    }
                    onChange={(event: any) => {
                      if (
                        !digitRegex.test(event.target.value.replace(/,/g, ""))
                      ) {
                        return;
                      }
                      setInterestRateString(event.target.value);
                      setInterestRate(
                        isDefined(event.target.value)
                          ? parseFloat(event.target.value.replace(/,/g, "")) /
                              100
                          : undefined
                      );
                    }}
                    onBlur={() => {
                      setCanShowError(true);
                      !isDefined(interestRate) && setInterestRate(0.045);
                    }}
                  />
                  <InputRightElement
                    fontWeight="500"
                    marginRight="10px"
                    children={<Box as={FaPercentage} />}
                  />
                </InputGroup>
                <InputTitle>Down Payment Percentage</InputTitle>
                <InputGroup>
                  <Input
                    background="white"
                    marginTop="1px"
                    placeholder="10%"
                    value={
                      percentageDownString?.endsWith(".")
                        ? percentageDownString
                        : isDefined(percentageDown)
                        ? (percentageDown * 100).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })
                        : ""
                    }
                    onChange={(event) => {
                      if (
                        !digitRegex.test(event.target.value.replace(/,/g, ""))
                      ) {
                        return;
                      }
                      setPercentageDownString(event.target.value);
                      setPercentageDown(
                        isDefined(event.target.value)
                          ? parseFloat(event.target.value.replace(/,/g, "")) /
                              100
                          : undefined
                      );
                    }}
                    onBlur={() => {
                      setCanShowError(true);
                      !isDefined(percentageDown) && setPercentageDown(0.0);
                    }}
                  />
                  <InputRightElement
                    fontWeight="500"
                    marginRight="10px"
                    children={<Box as={FaPercentage} />}
                  />
                </InputGroup>
              </Box>
            )}
            <Button
              fontWeight="600"
              color={primaryColor}
              fontSize="18px"
              border="0"
              marginTop="10px"
              variant="outline"
              onClick={() => setExpanded(!expanded)}
            >
              Advanced settings
              <Text
                as="span"
                className="icon"
                dangerouslySetInnerHTML={{
                  __html: expanded
                    ? feather.icons["chevron-up"].toSvg()
                    : feather.icons["chevron-down"].toSvg(),
                }}
              />
            </Button>
          </Container>
        </Section>
        <Section className="rightSection" display="flex">
          <Container
            className="calculation"
            marginRight="20px"
            textAlign="center"
            width="850px"
            height="fit-content"
          >
            <Box
              className="rightSectionTitle"
              display="flex"
              justifyContent="center"
            >
              <Box h="80px">
                {showError ? (
                  <StepTitle
                    className="shareTitle"
                    color="#FF4013"
                    mb="0px !important"
                    marginTop="20px"
                  >
                    Whoops
                  </StepTitle>
                ) : (
                  <StepTitle
                    className="shareTitle"
                    marginTop="20px"
                    mb="0px !important"
                  >
                    {showDashes
                      ? "See what you could qualify to save"
                      : lenderVersion
                      ? "Great! Your client qualifies for a big saving."
                      : "Great! You qualify for a big saving."}
                  </StepTitle>
                )}
                {showError && <Text color="#9BA0B4">{errorText}</Text>}
                {showDashes && (
                  <Text mt={4} color="#808288" fontWeight={500}>
                    Enter numbers on the left, calculate to see the results.
                  </Text>
                )}
              </Box>
            </Box>

            <Box
              className="mortgageBox"
              display="flex"
              background={primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"}
              marginTop="50px"
              justifyContent="center"
              maxWidth="600px"
              marginX="auto"
              borderRadius="5px"
              pb={showDashes && 4}
            >
              <Flex
                flexDirection="row"
                justifyContent="center"
                align-items="flex-start"
              >
                <Box padding={{ base: "15px", sm: "25px 35px 5px 30px" }}>
                  <Box>
                    <Text className="blackTitle">
                      LOWER STUDENT LOAN PAYMENTS BY
                    </Text>
                  </Box>
                  {showDashes && (
                    <Flex
                      alignItems="center"
                      className="bigPrice"
                      color={primaryColor}
                      justifyContent="center"
                    >
                      {" "}
                      <Box as={FaDollarSign} color={primaryColor} />{" "}
                      <Box
                        as={FaMinus}
                        color={primaryColor}
                        fontSize="1.25rem"
                        fontWeight={900}
                      />{" "}
                      <Box
                        as={FaMinus}
                        color={primaryColor}
                        fontSize="1.25rem"
                        fontWeight={900}
                        mr={-1}
                      />
                    </Flex>
                  )}
                  {!showDashes && (
                    <Flex
                      alignItems="center"
                      className="bigPrice"
                      color={primaryColor}
                      justifyContent="center"
                    >
                      {Math.round(studentLoanPaymentBefore()) -
                      Math.round(studentLoanPaymentAfter()) ? (
                        <Box
                          as={FaMinus}
                          color={primaryColor}
                          fontSize="1.25rem"
                          fontWeight={900}
                          mr={-1}
                        />
                      ) : (
                        ""
                      )}
                      <Box as={FaDollarSign} color={primaryColor} />
                      <Text ml={-1}>
                        {monthlySavings}
                        <span className="lowerMonth">/</span>
                        <span className="lowerMonth">Month</span>
                      </Text>
                    </Flex>
                  )}
                </Box>

                <Box padding={{ base: "15px", sm: "25px 35px 5px 30px" }}>
                  <Text className="blackTitle">
                    INCREASE HOME BUYING POWER BY
                  </Text>
                  {showDashes && (
                    <Flex
                      alignItems="center"
                      className="bigPrice"
                      color={primaryColor}
                      justifyContent="center"
                    >
                      {" "}
                      <Box as={FaDollarSign} color={primaryColor} />{" "}
                      <Box
                        as={FaMinus}
                        color={primaryColor}
                        fontSize="1.25rem"
                        fontWeight={900}
                      />{" "}
                      <Box
                        as={FaMinus}
                        color={primaryColor}
                        fontSize="1.25rem"
                        fontWeight={900}
                        mr={-1}
                      />
                    </Flex>
                  )}
                  {!showDashes && (
                    <Flex
                      className="bigPrice"
                      color={primaryColor}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {Math.round(
                        totalHomeLoan(studentLoanPaymentAfter()) -
                          Math.round(totalHomeLoan(studentLoanPaymentBefore()))
                      ) ? (
                        <Box
                          as={FaPlus}
                          color={primaryColor}
                          fontSize="1.25rem"
                          fontWeight={900}
                          mr={-1}
                        />
                      ) : (
                        ""
                      )}
                      <Box as={FaDollarSign} color={primaryColor} />
                      <Text ml={-1}>
                        {(
                          Math.round(totalHomeLoan(studentLoanPaymentAfter())) -
                          Math.round(totalHomeLoan(studentLoanPaymentBefore()))
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })}
                      </Text>
                    </Flex>
                  )}
                </Box>
              </Flex>
            </Box>
            {/* {!lenderVersion && showLeadCapture && lenderEmail && (
              <Stack
                className="mortgageBox"
                display="flex"
                background={primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"}
                marginTop="50px"
                justifyContent="center"
                maxWidth="600px"
                marginX="auto"
                borderRadius="5px"
                py={6}
                px={16}
                spacing={12}
                position="relative"
              >
                <Icon
                  name="info"
                  position="absolute"
                  right={4}
                  top={2}
                  onClick={onPrivacyOpen}
                />
                {showContact ? (
                  <Text color="#54595E" fontWeight={600}>
                    Please fill in your contact information so a mortgage loan
                    advisor can reach out to you.
                  </Text>
                ) : (
                  <Text color="#54595E" fontWeight={600}>
                    Would you like to speak with your mortgage loan advisor to
                    discuss your increased affordability?
                  </Text>
                )}
                {showContact ? (
                  <Stack alignItems="center">
                    <Flex w="100%">
                      <Input
                        placeholder="First Name"
                        value={clientFirstName}
                        onChange={(e) => setClientFirstName(e.target.value)}
                      />
                      <Input
                        ml={2}
                        placeholder="Last Name"
                        value={clientLastName}
                        onChange={(e) => setClientLastName(e.target.value)}
                      />
                    </Flex>
                    <Flex w="100%">
                      <Input
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) =>
                          setPhone(
                            e.target.value < 5
                              ? new AsYouType().input(e.target.value)
                              : new AsYouType("US").input(e.target.value)
                          )
                        }
                      />
                      <Input
                        placeholder="Email"
                        ml={2}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Text color="red.500">{emailError}</Text>
                    </Flex>
                    <Button
                      bg={primaryColor}
                      w="145px"
                      h="30px"
                      borderRadius="8px"
                      mt={2}
                      _disabled={{
                        bg:
                          primaryColor === "indigo"
                            ? "indigo70"
                            : `${primaryColor}b3`,
                      }}
                      disabled={!email || !clientFirstName || !clientLastName}
                      onClick={sendConsumerEmail}
                    >
                      Send
                    </Button>
                  </Stack>
                ) : (
                  <Flex alignItems="center" justifyContent="center">
                    <Button
                      background="white"
                      color="#4F4F4F"
                      border="1px solid"
                      w="145px"
                      h="30px"
                      borderRadius="8px"
                      _hover={{ background: "lightgrey" }}
                      onClick={() => setShowLeadCapture(false)}
                    >
                      No, thank you
                    </Button>
                    <Button
                      backgroundColor={primaryColor}
                      w="145px"
                      h="30px"
                      borderRadius="8px"
                      ml={6}
                      onClick={() => setShowContact(true)}
                    >
                      Yes
                    </Button>
                  </Flex>
                )}
              </Stack>
            )} */}

            {!showDashes && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                background={primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"}
                maxWidth="600px"
                marginX="auto"
                marginTop="0px"
              >
                <Button
                  backgroundColor={
                    primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"
                  }
                  fontWeight="550"
                  fontSize="15px"
                  border="1"
                  marginTop="10px"
                  variant="outline"
                  onClick={() => setDrop(!drop)}
                >
                  <Text color={primaryColor}>
                    {drop ? "Hide Details" : "View Details"}
                  </Text>
                </Button>
              </Box>
            )}

            {drop && (
              <Box
                display="flex"
                background={primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"}
                justifyContent="center"
                maxWidth="600px"
                marginX="auto"
                height={{ base: "585px", md: "44vh" }}
              >
                <Wrapper
                  justifyContent="center"
                  marginTop="40px"
                  marginBottom="20px"
                >
                  <Box padding="0px 20px 0px 25px" textAlign="left">
                    <Text
                      className="bigTitle"
                      color="#9BA0B4"
                      marginBottom="15px"
                    >
                      BEFORE
                    </Text>
                    <HorizontalLine border="1px solid #5B6179"></HorizontalLine>
                    <Text className="grayTitle">STUDENT LOAN PAYMENT</Text>
                    <Flex
                      align="center"
                      justify="space-between"
                      marginBottom="15px"
                    >
                      <Text className="grayPrice">
                        $
                        {Math.round(studentLoanPaymentBefore()).toLocaleString(
                          "en-US",
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                      </Text>
                      <Text
                        color="#5B6179"
                        marginRight="auto"
                        paddingLeft="7px"
                      >
                        /Month
                      </Text>
                    </Flex>

                    <HorizontalLine border="1px solid #5B6179"></HorizontalLine>
                    <Text className="grayTitle">MORTGAGE {who} CAN AFFORD</Text>
                    <Text className="grayPrice">
                      $
                      {Math.round(
                        totalHomeLoan(studentLoanPaymentBefore())
                      ).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                      })}
                    </Text>
                    <HorizontalLine border="1px solid #5B6179"></HorizontalLine>
                    <Text className="grayTitle">PAYMENT {who} CAN AFFORD</Text>
                    <Flex align="center" justify="space-between">
                      <Text className="grayPrice">
                        $
                        {Math.round(
                          monthlyMortgagePayment(studentLoanPaymentBefore())
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })}
                      </Text>
                      <Text
                        color="#5B6179"
                        marginRight="auto"
                        paddingLeft="7px"
                      >
                        /Month
                      </Text>
                    </Flex>
                  </Box>

                  <Box
                    padding="0px 20px 0px 25px"
                    textAlign="left"
                    height="370px"
                  >
                    <Text className="bigTitle" color={primaryColor}>
                      AFTER
                    </Text>
                    <HorizontalLine
                      border="1px solid"
                      borderColor={primaryColor}
                    ></HorizontalLine>
                    <Text className="colorTitle" color={primaryColor}>
                      STUDENT LOAN PAYMENT
                    </Text>
                    <Flex align="center" justify="space-between">
                      <Text className="colorPrice" color={primaryColor}>
                        $
                        {studentLoanPaymentAfter() < 0
                          ? 0
                          : Math.round(
                              studentLoanPaymentAfter()
                            ).toLocaleString("en-US", {
                              maximumFractionDigits: 0,
                            })}
                      </Text>
                      <Text
                        color={primaryColor}
                        marginRight="auto"
                        paddingLeft="7px"
                      >
                        /Month
                      </Text>
                    </Flex>
                    <HorizontalLine
                      border="1px solid"
                      borderColor={primaryColor}
                    ></HorizontalLine>
                    <Text className="colorTitle" color={primaryColor}>
                      MORTGAGE {who} CAN AFFORD
                    </Text>
                    <Text className="colorPrice" color={primaryColor}>
                      $
                      {Math.round(
                        totalHomeLoan(studentLoanPaymentAfter())
                      ).toLocaleString("en-US", {
                        maximumFractionDigits: 0,
                      })}
                    </Text>
                    <HorizontalLine
                      border="1px solid"
                      borderColor={primaryColor}
                    ></HorizontalLine>
                    <Text className="colorTitle" color={primaryColor}>
                      PAYMENT {who} CAN AFFORD
                    </Text>
                    <Flex align="center" justify="space-between">
                      <Text className="colorPrice" color={primaryColor}>
                        $
                        {Math.round(
                          monthlyMortgagePayment(studentLoanPaymentAfter())
                        ).toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })}
                      </Text>
                      <Text
                        color={primaryColor}
                        marginRight="auto"
                        paddingLeft="7px"
                      >
                        /Month
                      </Text>
                    </Flex>
                  </Box>
                </Wrapper>
              </Box>
            )}
            {showDashes && (
              <Text
                fontWeight="900"
                fontFamily="proxima-nova,sans-serif"
                position="center"
                fontSize="15px"
                marginTop="35px"
              >
                {lenderVersion ? (
                  <Text as="span">
                    Share this tool and let clients discover their potential
                    saving.
                  </Text>
                ) : (
                  <Text as="span">
                    Skip this step. Lower my student loan payment now.{" "}
                    <ChakraLink
                      color={primaryColor}
                      href={`https://app.myloansense.com${
                        partnerCode ? `?partner=${partnerCode}` : ""
                      }`}
                    >
                      Sign Up
                    </ChakraLink>{" "}
                  </Text>
                )}
              </Text>
            )}
            {lenderVersion && showDashes && (
              <>
                <Button
                  variant="outline"
                  w="320px"
                  mt={4}
                  h="56px"
                  onClick={() => {
                    setModalType("invite-client-no-results");
                    onOpen();
                  }}
                >
                  Invite Client
                </Button>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  mt={6}
                  onClick={() => {
                    setModalType("share");
                    onOpen();
                  }}
                >
                  <Box as={FiShare} color={primaryColor} fontWeight={700} />
                  <Text color={primaryColor} ml={2} fontWeight={700}>
                    Share
                  </Text>
                </Flex>
              </>
            )}
            {!showDashes && lenderVersion && (
              <Text
                fontWeight="900"
                fontFamily="proxima-nova,sans-serif"
                position="center"
                fontSize="15px"
                marginTop="35px"
              >
                Share the results and invite clients to get their rates.
              </Text>
            )}
            {!showDashes && (
              <ShareButton
                marginTop={"10px"}
                position={"center"}
                fontSize={"12px"}
                color={"white"}
                backgroundColor={primaryColor}
                borderColor={"indigo"}
                padding={"29px"}
                onClick={() => {
                  if (lenderVersion) {
                    window.parent.postMessage({
                      totalAffordability: Math.round(
                        totalHomeLoan(studentLoanPaymentAfter())
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      }),
                      increasedPurchasingPower,
                      analysisID: prefilledData?.analysisID,
                      lenderIdentifier: prefilledData?.lenderIdentifer,
                      eventName: "loansense.increased_purchasing_power",
                      income: annualIncome,
                      term: loanTerm * 12,
                      percentageDown,
                      minCreditCardPayment,
                      otherLoans,
                      carPayment,
                      federalStudentLoanBalance,
                      studentLoanInterest,
                      familySize,
                      debtToIncome,
                      interestRate,
                      studentLoanPayment: studentLoanPaymentAfter(),
                    });
                    onOpen();
                    setModalType("invite-client-results");
                  } else {
                    window.location.href = `https://app.myloansense.com${
                      partnerCode ? `?partner=${partnerCode}` : ""
                    }`;
                  }
                }}
              >
                <BsHouseFill
                  style={{
                    color: "white",
                    fontSize: "25px",
                    fontWeight: 900,
                    marginRight: "10px",
                    alignSelf: "center",
                  }}
                />
                {lenderVersion
                  ? "INVITE CLIENT TO LOANSENSE"
                  : "SIGN UP FOR LOANSENSE"}
              </ShareButton>
            )}
            <Box className="poweredBy" marginTop="35px">
              <Text
                color={primaryColor}
                fontStyle="italic"
                fontSize="12px"
                fontWeight="500"
              >
                Powered By
              </Text>
              <Image
                width="auto"
                height="30px"
                src={Logo}
                marginBottom="10px"
              />
            </Box>
            {!lenderVersion && (
              <Box textAlign="left" padding="10px 15px">
                <Text color="#9BA0B4" fontWeight="500" fontSize="11px">
                  Disclaimer: This is not an offer to refinance your student
                  loans. LoanSense is a consumer software and educational
                  service that helps you enroll into a different federal
                  repayment program with your existing student loan servicer(s).
                  LoanSense works with your lender but is a separate service
                  from your lender. This tool does not imply or guarantee a loan
                  approval. By using LoanSense service, you agree to the full
                  terms of service.
                </Text>
              </Box>
            )}
          </Container>
        </Section>
      </Wrapper>
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="5px"
          w="642px"
          maxW="90%"
          maxH="90vh"
          p={6}
          overflowY="auto"
        >
          <ModalHeader
            fontWeight={900}
            fontSize="26px"
            paddingBottom="0"
            textAlign="center"
            my={4}
          >
            {modalType === "share" ? "Share" : "Invite A Client To LoanSense"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody maxW="100%">
            {modalType === "share" && (
              <Text textAlign="center">
                Share your unique consumer-facing tool and lead capture. We’ll
                email you every lead. Copy url or embed code for your client to
                enter their own data.
              </Text>
            )}
            {modalType === "share" && (
              <Tabs mt={6} onChange={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab>Share URL</Tab>
                  <Tab>Embed Code</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Text
                      background="#F6F7FF"
                      p={2}
                      borderRadius="3px"
                      mt={4}
                      border="1px solid #7E8FF5"
                    >
                      https://www.myloansense.com/c/
                      {partnerCode.toLowerCase()}
                    </Text>
                  </TabPanel>
                  <TabPanel>
                    <Text
                      background="#F6F7FF"
                      p={2}
                      borderRadius="3px"
                      mt={4}
                      border="1px solid #7E8FF5"
                    >
                      {buildWidgetCode({
                        partnerCode,
                        apiKey,
                        organization,
                        lenderEmail,
                        nmls,
                        lenderPhone,
                      })}
                    </Text>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
            {modalType !== "share" && (
              <>
                <Input
                  background="white"
                  placeholder="Client Email"
                  isRequired
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  background="white"
                  mt={4}
                  placeholder="Client Phone"
                  isRequired
                  value={phone}
                  onChange={(e) =>
                    setPhone(
                      e.target.value < 5
                        ? new AsYouType().input(e.target.value)
                        : new AsYouType("US").input(e.target.value)
                    )
                  }
                />
                <Text color="red.500">{emailError}</Text>
                <Flex mt={6} justifyContent="space-between">
                  <Box w="45%">
                    <Box as="label" color="#484F6F">
                      Client First Name
                    </Box>
                    <Input
                      background="white"
                      placeholder="John"
                      isRequired
                      mt={2}
                      value={firstName}
                      onChange={(e) => {
                        setFormError("");
                        setFirstName(e.target.value);
                      }}
                    />
                    <Text color="red.500">{formError}</Text>
                  </Box>
                  <Box w="45%">
                    <Box as="label" color="#484F6F">
                      Client Last Name (optional)
                    </Box>
                    <Input
                      background="white"
                      placeholder="Miller"
                      mt={2}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Flex>
                <Box>
                  <Button
                    mt="20px"
                    color="black"
                    border="solid 1px #CBD5E0"
                    colorScheme="white"
                    onClick={handleToggle}
                    w="100%"
                    fontSize={{ base: "12px", lg: "16px" }}
                    fontWeight="700"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Text as="span">Help Tailor Our Support (optional)</Text>

                    <Text
                      as="span"
                      className="icon"
                      dangerouslySetInnerHTML={{
                        __html: show
                          ? feather.icons["chevron-down"].toSvg()
                          : feather.icons["chevron-right"].toSvg(),
                      }}
                    />
                  </Button>
                  <FormHelperText fontSize="11px">
                    *20 seconds to help close another loan
                  </FormHelperText>
                  <Collapse mt={4} in={show} animateOpacity>
                    <FormControl>
                      <FormLabel>
                        When does your client expect to close?
                      </FormLabel>
                      <Input
                        background="white"
                        placeholder="Date"
                        type="date"
                        onChange={(e) => setCloseDate(e.target.value)}
                        value={closeDate}
                        min={new Date().toISOString().split("T")[0]}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt="20px" lineHeight="20px">
                        What is the max monthly payment your client’s student
                        loan would need to be in order to afford their home?
                      </FormLabel>
                      <Input
                        background="white"
                        mt="5px"
                        placeholder="$0.00"
                        value={
                          isDefined(maximumMonthlyPayment)
                            ? maximumMonthlyPayment?.toLocaleString("en-US")
                            : ""
                        }
                        onChange={(event: any) => {
                          if (/\D/.test(event.target.value.replace(/,/g, ""))) {
                            return;
                          }
                          setCanShowError(false);
                          setMaximumMonthlyPayment(
                            isDefined(event.target.value)
                              ? parseInt(
                                  event.target.value.replace(/,/g, ""),
                                  10
                                )
                              : 0
                          );
                        }}
                      />
                    </FormControl>
                    <Box mt="20px">
                      <Text>
                        What mortgage product are you considering them for?
                      </Text>
                    </Box>
                    <Select
                      mt="5px"
                      placeholder="Select an option"
                      value={mortgageOption}
                      onChange={(e) => setMortgageOption(e.target.value)}
                    >
                      <option value="freddie">Freddie</option>
                      <option value="fannie">Fannie</option>
                      <option value="fha">FHA</option>
                      <option value="va">VA</option>
                      <option value="usda">USDA</option>
                      <option value="non-qm">Non QM Loans</option>
                    </Select>
                    <Box mt="20px">
                      <Text>Main reason for inviting the client?</Text>
                    </Box>
                    <Select
                      mt="5px"
                      placeholder="Select an option"
                      value={reasonForInvite}
                      onChange={(e) => setReasonForInvite(e.target.value)}
                    >
                      <option value="reduce-payment">
                        Needs to reduce payment to close on a home
                      </option>
                      <option value="student-loan-help">
                        Does NOT need to reduce payment but needs student loan
                        help
                      </option>
                    </Select>
                  </Collapse>
                </Box>
                <Flex alignItems="center" mt={12}>
                  <Text
                    fontWeight={900}
                    color={primaryColor}
                    fontSize="18px"
                    mr={2}
                  >
                    WHO WILL PAY FOR THE SERVICE?
                  </Text>
                  <Hint
                    iconName="question"
                    direction="right"
                    message="A small service fee could earn you thousands of extra in loan value and lower the barrier to your client getting started. We won’t ask you to pay now."
                    color="#2e49ec"
                  />
                </Flex>
                <Text
                  mt={2}
                  mb={4}
                  color="black"
                  fontSize="14px"
                  fontWeight="700"
                >
                  Will you, the lender, pay to increase your client's home
                  buying power?
                </Text>
                <RadioGroup
                  value={subsidize}
                  onChange={(val) => setSubsidize(val)}
                >
                  <Radio value="yes" size="md" borderColor="#484F6F" mb={2}>
                    <Text fontWeight="400" fontSize="14px">
                      Yes, I will pay to increase my client's buying power.
                    </Text>
                  </Radio>
                  <Radio value="no" size="md" borderColor="#484F6F">
                    <Text fontWeight="400" fontSize="14px">
                      No, my client will pay LoanSense for the service
                    </Text>
                  </Radio>
                </RadioGroup>
                {modalType === "invite-client-results" && (
                  <>
                    <Flex alignItems="center" mt={6}>
                      <Text
                        fontWeight={900}
                        color={primaryColor}
                        fontSize="18px"
                        mr={2}
                      >
                        INCLUDE THE RESULTS?
                      </Text>
                      <Hint
                        iconName="question"
                        direction="right"
                        message="Clients may benefit from knowing their personalized result before signing up. You may turn off this option if the results are not important to the client."
                        color="#2e49ec"
                      />
                    </Flex>
                    <Flex alignItems="center">
                      <Text mt={2} mb={4} color="#484F6F" w="70%">
                        Keep this on to include in the invitation the results
                        calculated with the tool. Turn off if you want to hide
                        it.
                      </Text>
                      <Flex justifyContent="flex-end" flex={1}>
                        <Switch
                          onChange={(e) => setIncludeResults(e.target.checked)}
                          size="lg"
                          background-color={primaryColor}
                          isChecked={includeResults}
                        />
                      </Flex>
                    </Flex>
                    {includeResults && (
                      <Box
                        className="mortgageBox"
                        display="flex"
                        background={
                          primaryColor === "indigo" ? "#F6F7FF" : "#F7F7F9"
                        }
                        borderRadius="10px"
                        pb={showDashes && 4}
                      >
                        <Stack>
                          <Flex
                            padding={{ base: "15px", sm: "25px 35px 5px 30px" }}
                            alignItems="center"
                          >
                            <Text className="blackTitle" fontWeight={900}>
                              LOWER STUDENT LOANS BY
                            </Text>
                            {showDashes && (
                              <Flex
                                alignItems="center"
                                className="bigPrice"
                                color={primaryColor}
                                justifyContent="center"
                                ml={4}
                              >
                                {" "}
                                <Box
                                  as={FaDollarSign}
                                  color={primaryColor}
                                />{" "}
                                <Box
                                  as={FaMinus}
                                  color={primaryColor}
                                  fontSize="1.25rem"
                                  fontWeight={900}
                                />{" "}
                                <Box
                                  as={FaMinus}
                                  color={primaryColor}
                                  fontSize="1.25rem"
                                  fontWeight={900}
                                  mr={-1}
                                />
                              </Flex>
                            )}
                            {!showDashes && (
                              <Flex
                                alignItems="center"
                                className="bigPrice"
                                fontWeight={900}
                                fontSize="35px"
                                color={primaryColor}
                                justifyContent="center"
                                ml={4}
                              >
                                {Math.round(studentLoanPaymentBefore()) -
                                Math.round(studentLoanPaymentAfter()) ? (
                                  <Box
                                    as={FaMinus}
                                    color={primaryColor}
                                    fontSize={{ base: "28px", sm: "1.25rem" }}
                                    fontWeight={900}
                                    mr={-1}
                                  />
                                ) : (
                                  ""
                                )}
                                <Box
                                  as={FaDollarSign}
                                  color={primaryColor}
                                  fontSize={{ base: "28px", sm: "1.25rem" }}
                                />
                                <Text ml={-1}>
                                  {(
                                    Math.round(studentLoanPaymentBefore()) -
                                    Math.round(studentLoanPaymentAfter())
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 0,
                                  })}
                                  <Box as="span" fontSize="16px" ml="5px">
                                    /Month
                                  </Box>
                                </Text>
                              </Flex>
                            )}
                          </Flex>

                          {/* <Box
                  className="colorLine"
                  width="1px"
                  height="110px"
                  backgroundColor="#7d83a0"
                  marginTop="15px"
                ></Box> */}
                          <Flex
                            padding={{ base: "15px", sm: "25px 35px 5px 30px" }}
                            alignItems="center"
                          >
                            <Text className="blackTitle" fontWeight={900}>
                              INCREASE HOME BUYING POWER BY
                            </Text>
                            {showDashes && (
                              <Flex
                                alignItems="center"
                                className="bigPrice"
                                fontWeight={900}
                                fontSize="35px"
                                color={primaryColor}
                                justifyContent="center"
                                ml={4}
                              >
                                {" "}
                                <Box
                                  as={FaDollarSign}
                                  fontSize={{ base: "28px", sm: "1.25rem" }}
                                  color={primaryColor}
                                />{" "}
                                <Box
                                  as={FaMinus}
                                  color={primaryColor}
                                  fontSize={{ base: "28px", sm: "1.25rem" }}
                                  fontWeight={900}
                                />{" "}
                                <Box
                                  as={FaMinus}
                                  color={primaryColor}
                                  fontSize="1.25rem"
                                  fontWeight={900}
                                  mr={-1}
                                />
                              </Flex>
                            )}
                            {!showDashes && (
                              <Flex
                                className="bigPrice"
                                color={primaryColor}
                                fontWeight={900}
                                fontSize="35px"
                                alignItems="center"
                                justifyContent="center"
                                ml={4}
                              >
                                {Math.round(
                                  totalHomeLoan(studentLoanPaymentAfter()) -
                                    Math.round(
                                      totalHomeLoan(studentLoanPaymentBefore())
                                    )
                                ) ? (
                                  <Box
                                    as={FaPlus}
                                    color={primaryColor}
                                    fontSize="1.25rem"
                                    fontWeight={900}
                                    mr={-1}
                                  />
                                ) : (
                                  ""
                                )}
                                <Box as={FaDollarSign} color={primaryColor} />
                                <Text ml={-1}>
                                  {(
                                    Math.round(
                                      totalHomeLoan(studentLoanPaymentAfter())
                                    ) -
                                    Math.round(
                                      totalHomeLoan(studentLoanPaymentBefore())
                                    )
                                  ).toLocaleString("en-US", {
                                    maximumFractionDigits: 0,
                                  })}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                        </Stack>
                      </Box>
                    )}
                  </>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" mt={4}>
            {modalType === "share" && (
              <Button
                width="195px"
                bg={primaryColor}
                _hover={{ bg: primaryColor }}
                _active={{ bg: primaryColor }}
                _disabled={{ bg: "rgba(46, 73, 236, 0.7)" }}
                onClick={() => {
                  if (tabIndex === 0) {
                    copyToClipboard(
                      `https://www.myloansense.com/c/${partnerCode.toLowerCase()}`
                    );
                  } else {
                    copyToClipboard(
                      buildWidgetCode({
                        organization,
                        partnerCode,
                        lenderEmail,
                        nmls,
                        lenderPhone,
                        apiKey,
                      })
                    );
                  }
                }}
              >
                COPY
              </Button>
            )}
            {modalType !== "share" && (
              <Button
                width="195px"
                bg={primaryColor}
                _hover={{ bg: primaryColor }}
                _active={{ bg: primaryColor }}
                _disabled={{ bg: "rgba(46, 73, 236, 0.7)" }}
                disabled={!email || !subsidize}
                onClick={sendEmail}
              >
                SEND INVITE
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isConfirmOpen}
        isCentered
        blockScrollOnMount
        onClose={() => {
          if (lenderVersion) {
            window.location.reload();
          }
          onConfirmClose();
          setShowLeadCapture(false);
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="5px" margin="10px">
          <ModalHeader
            fontWeight={900}
            fontSize="26px"
            paddingBottom="0"
            textAlign="center"
            mt={4}
          >
            {lenderVersion ? (
              <Stack>
                <Box
                  as={FaCheckCircle}
                  color={primaryColor}
                  fontSize="40px"
                  margin="0 auto"
                />
                <Text as="span">Invite Sent</Text>
              </Stack>
            ) : (
              "Sent!"
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={4}>
            {lenderVersion && (
              <>
                <Text fontWeight="500" fontSize="16px" textAlign="center">
                  Invitation was sent to{" "}
                  <Text as="span" fontWeight={900}>
                    {email}
                  </Text>
                </Text>
                {subsidize === "yes" ? (
                  <Text textAlign="center" fontSize="12px" mt={4}>
                    You will be cc-ed to an email inviting your client to get
                    started with LoanSense. You have elelected to pay for your
                    clients loan reduction service.
                  </Text>
                ) : (
                  <Text textAlign="center" fontSize="12px" mt={4}>
                    You will be cc-ed to an email inviting your client to get
                    started with LoanSense. You have elected to have your client
                    pay for their loan reduction service.
                  </Text>
                )}
                <Text textAlign="center" fontSize="12px" mt={4}>
                  For support, contact us at hello@myloansense.com
                </Text>
              </>
            )}
            {!lenderVersion && (
              <Text textAlign="center">
                Thank you for sending us your contact! Someone will reach out to
                you within a few days.
              </Text>
            )}
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" mt={2}>
            <Button
              fontSize="12px"
              width="195px"
              bg={primaryColor}
              _hover={{ bg: primaryColor }}
              _active={{ bg: primaryColor }}
              _disabled={{ bg: "rgba(46, 73, 236, 0.7)" }}
              onClick={() => {
                if (lenderVersion) {
                  window.location.reload();
                }
                onConfirmClose();
                setShowLeadCapture(false);
              }}
            >
              {lenderVersion ? "CLOSE" : "OK"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isPrivacyOpen}
        isCentered
        blockScrollOnMount
        onClose={onPrivacyClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="5px" margin="10px" bg="#F6F7FF" p={4}>
          <ModalHeader
            fontWeight={900}
            fontSize="26px"
            paddingBottom="0"
            textAlign="center"
            mt={4}
          >
            <Stack>
              <Image w={100} src={privacyIcon} margin="0 auto" />
              <Text as="span">Privacy</Text>
            </Stack>
          </ModalHeader>
          <ModalBody padding={4}>
            <Text textAlign="center" fontSize="12px" mt={4}>
              Information you provide to us will be used by LoanSense and your
              lender. You can opt out of communication with all parties at any
              time. use of lender services is subjected to their terms and
              privacy policy. LoanSense does not own or control third parties.
            </Text>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" mt={2}>
            <Button
              fontSize="12px"
              width="195px"
              bg={primaryColor}
              _hover={{ bg: primaryColor }}
              _active={{ bg: primaryColor }}
              _disabled={{ bg: "rgba(46, 73, 236, 0.7)" }}
              onClick={() => {
                onPrivacyClose();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </WidgetContainer>
  );
};

export default Widget;
